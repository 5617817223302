/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../public/fonts/open-sans-v27-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../public/fonts/open-sans-v27-latin-300.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../public/fonts/open-sans-v27-latin-300.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../public/fonts/open-sans-v27-latin-300.woff') format('woff'),
    /* Modern Browsers */ url('../public/fonts/open-sans-v27-latin-300.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../public/fonts/open-sans-v27-latin-300.svg#OpenSans')
      format('svg'); /* Legacy iOS */
}
/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../public/fonts/open-sans-v27-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../public/fonts/open-sans-v27-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../public/fonts/open-sans-v27-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../public/fonts/open-sans-v27-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */ url('../public/fonts/open-sans-v27-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../public/fonts/open-sans-v27-latin-regular.svg#OpenSans')
      format('svg'); /* Legacy iOS */
}
/* open-sans-500 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  src: url('../public/fonts/open-sans-v27-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../public/fonts/open-sans-v27-latin-500.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../public/fonts/open-sans-v27-latin-500.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../public/fonts/open-sans-v27-latin-500.woff') format('woff'),
    /* Modern Browsers */ url('../public/fonts/open-sans-v27-latin-500.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../public/fonts/open-sans-v27-latin-500.svg#OpenSans')
      format('svg'); /* Legacy iOS */
}
/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../public/fonts/open-sans-v27-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../public/fonts/open-sans-v27-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../public/fonts/open-sans-v27-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../public/fonts/open-sans-v27-latin-700.woff') format('woff'),
    /* Modern Browsers */ url('../public/fonts/open-sans-v27-latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../public/fonts/open-sans-v27-latin-700.svg#OpenSans')
      format('svg'); /* Legacy iOS */
}
/* open-sans-italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url('../public/fonts/open-sans-v27-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../public/fonts/open-sans-v27-latin-italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../public/fonts/open-sans-v27-latin-italic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../public/fonts/open-sans-v27-latin-italic.woff')
      format('woff'),
    /* Modern Browsers */ url('../public/fonts/open-sans-v27-latin-italic.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../public/fonts/open-sans-v27-latin-italic.svg#OpenSans')
      format('svg'); /* Legacy iOS */
}

/* open-sans-condensed-300 - latin */
@font-face {
  font-family: 'Open Sans Condensed';
  font-style: normal;
  font-weight: 300;
  src: url('../public/fonts/open-sans-condensed-v15-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../public/fonts/open-sans-condensed-v15-latin-300.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../public/fonts/open-sans-condensed-v15-latin-300.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../public/fonts/open-sans-condensed-v15-latin-300.woff')
      format('woff'),
    /* Modern Browsers */ url('../public/fonts/open-sans-condensed-v15-latin-300.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../public/fonts/open-sans-condensed-v15-latin-300.svg#OpenSansCondensed') format('svg'); /* Legacy iOS */
}
/* open-sans-condensed-700 - latin */
@font-face {
  font-family: 'Open Sans Condensed';
  font-style: normal;
  font-weight: 700;
  src: url('../public/fonts/open-sans-condensed-v15-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../public/fonts/open-sans-condensed-v15-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../public/fonts/open-sans-condensed-v15-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../public/fonts/open-sans-condensed-v15-latin-700.woff')
      format('woff'),
    /* Modern Browsers */ url('../public/fonts/open-sans-condensed-v15-latin-700.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../public/fonts/open-sans-condensed-v15-latin-700.svg#OpenSansCondensed') format('svg'); /* Legacy iOS */
}
