.App {
  text-align: center;
  height: 100%;
  width: 100%;
}

@import url('font.css');

* {
  box-sizing: border-box;
}
